<template>
  <div class="main__content">
    <div class="table__header_bar">
      <InputSearch isFilter @detectSearchValue="searchTableHandler" />
    </div>
    <Table
      v-if="getTableOfTourAccessAdmin.table"
      :content="getTableOfTourAccessAdmin.table.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import { mapGetters } from 'vuex';

export default {
  components: {
    Table,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getTableOfTourAccessAdmin']),
  },
  created() {
    this.$store.dispatch('getTableOfTourAccessAdmin');
  },
  methods: {
    tableHandler() {
      this.$store.dispatch('getTableOfTourAccessAdmin');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfTourAccessAdmin');
    },
  },
};
</script>

<style lang="sass" scoped>
.access__admin-content
  padding: 64px
</style>
